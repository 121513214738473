<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px" size="mini">
      <el-form-item :label="$t('datacenter.dataQuality.monitoringTask.title')">
        <el-input v-model="form.schemeName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('datacenter.dataQuality.monitoringTask.label')">
        <el-input v-model="form.label"></el-input>
      </el-form-item>
      <el-form-item :label="$t('datacenter.dataQuality.monitoringTask.remark')">
        <el-input type="textarea" v-model="form.remark"></el-input>
      </el-form-item>
      <el-form-item :label="$t('datacenter.dataQuality.monitoringTask.cycleSetting')">
        <el-row>
          <el-col span="18">
            <el-input readonly :value="form.corn"></el-input>
          </el-col>
          <el-col span="6"><el-button @click="cronDialogVisible = true" size="mini">{{$t('datacenter.dataQuality.monitoringTask.select')}}</el-button></el-col>
        </el-row>
      </el-form-item>
      <!-- <el-form-item label="检测参数数量：">
        <el-button>查看</el-button>
      </el-form-item> -->
      <el-form-item>
      <el-card style="padding:10px 20px;width:80%;">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step :title="'1'+$t('datacenter.dataQuality.monitoringTask.selectRuleTem')"></el-step>
        <el-step :title="'2'+$t('datacenter.dataQuality.monitoringTask.selectDataObj')"></el-step>
        <el-step :title="'3'+$t('datacenter.dataQuality.monitoringTask.selectJudgeMethod')"></el-step>
      </el-steps>

      <div v-if="active == 0">
        <!-- <ul class="rule-tem-list">
          <li v-for="item in ruleTemData" @click="clickRuleItem(item)" :class="form.ruleTemplate == item.id ? 'active' : ''">{{item.name}}</li>
        </ul> -->
        <div style="display: flex;justify-content: space-between">
          <div>
           <h3>{{ $t('datacenter.dataQuality.monitoringTask.accuracyVerif') }}</h3>
            <!-- <br> -->
            <el-radio-group v-model="form.detectionType"  @change="Changeradio($event)">
        <el-radio label="rt_1_1_1">{{$t('datacenter.dataQuality.monitoringTask.testNum')}}</el-radio>
        <br>
        <el-radio label="rt_1_1_2_1">{{$t('datacenter.dataQuality.monitoringTask.tooBig')}}</el-radio>
        <br>
        <el-radio label="rt_1_1_2_2">{{$t('datacenter.dataQuality.monitoringTask.tooSmall')}}</el-radio>
      </el-radio-group>
          </div>
          <div>
            <h3>{{ $t('datacenter.dataQuality.monitoringTask.consistencyVerif') }}</h3>

            <el-radio-group v-model="form.detectionType" @change="Changeradio($event)">
        <el-radio label="rt_3_1">{{$t('datacenter.dataQuality.monitoringTask.b1')}}</el-radio>
        <br>
        <el-radio label="rt_3_1_1">{{$t('datacenter.dataQuality.monitoringTask.b2')}}</el-radio>
        <br>
        <el-radio label="rt_3_1_2">{{$t('datacenter.dataQuality.monitoringTask.b3')}}</el-radio>
      </el-radio-group>

          </div>
          <div>
            <h3> {{ $t('datacenter.dataQuality.monitoringTask.timelinessVerif') }}</h3>

            <el-radio-group v-model="form.detectionType" @change="Changeradio($event)">

        <el-radio label="rt_2_1_1">{{$t('datacenter.dataQuality.monitoringTask.c1')}}</el-radio>
        <br>
        <el-radio label="rt_2_1_2">{{$t('datacenter.dataQuality.monitoringTask.c2')}}</el-radio>
        <br>
      </el-radio-group>
          </div>
        </div>


      </div>
      <div v-if="active == 1">
        <el-form-item :label="$t('datacenter.dataQuality.monitoringTask.selectDataObj')">
            <div style="text-align: right;margin-bottom: 5px;"><el-button @click="addTemplate" type="primary" size="mini">添加</el-button></div>
            <el-row v-for="(item, index) in templateSelection" :key="item.code" :gutter="10" style="margin-bottom: 5px;">
              <el-col span="18">
                <el-input readonly :value="item.code"></el-input>
              </el-col>
              <el-col span="6">
                <el-button @click="clickSelectDataObj(index)" size="mini">{{$t('datacenter.dataQuality.monitoringTask.select')}}</el-button>
                <el-button icon="el-icon-delete" circle @click="delTemplate(index)"></el-button>
              </el-col>
            </el-row>
          </el-form-item>
      </div>
      <div v-if="active == 2">
        <el-button size="mini" @click="addDater" style="margin-bottom:10px;">{{$t('cip.cmn.btn.addBtn')}}</el-button>
        <el-table border :data="deterTableData" size="mini">
          <el-table-column
            prop="methodName"
            :label="$t('datacenter.dataQuality.monitoringTask.conditionName')">
            <template slot-scope="{row}">
              <el-input v-model="row.methodName" size="mini"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="warningLevel"
            :label="$t('datacenter.dataQuality.monitoringTask.alarmLevel')">
            <template slot-scope="{row}">
              <el-select v-model="row.warningLevel" size="mini">
                <el-option :label="$t('datacenter.dataQuality.monitoringTask.low')" :value="1"></el-option>
                <el-option :label="$t('datacenter.dataQuality.monitoringTask.middle')" :value="2"></el-option>
                <el-option :label="$t('datacenter.dataQuality.monitoringTask.high')" :value="3"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="180px"
            align="center">
            <template slot-scope="{row,$index}">
              <el-button size="mini" @click="setDater(row,$index)">{{$t('datacenter.dataQuality.monitoringTask.setRule')}}</el-button>
              <el-button size="mini" @click="delDater(row,$index)" style="margin-left:10px;">{{$t('cip.cmn.btn.delBtn')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align:center;margin-top:10px;">
        <el-button size="mini" :disabled="active <= 0" @click="prev">{{$t('datacenter.btn.prev')}}</el-button>
        <el-button size="mini" :disabled="active >= 2" @click="next" style="margin-left:10px;">{{$t('datacenter.btn.next')}}</el-button>
      </div>
      </el-card>
      </el-form-item>
      <el-form-item :label="$t('datacenter.dataQuality.monitoringTask.isExecute')">
        <el-radio-group v-model="form.runType">
          <el-radio :label="1">{{$t('datacenter.dataQuality.monitoringTask.isExecute') }}</el-radio>
          <el-radio :label="2">{{$t('datacenter.dataQuality.monitoringTask.manualExecution') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{$t('cip.cmn.btn.saveBtn')}}</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
        :title="$t('datacenter.dataQuality.monitoringTask.cycleSetting')"
        :visible.sync="cronDialogVisible">
      <cron v-model="cron" class="cron"></cron>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cronDialogConfirm">{{$t('submitText')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog
        v-if="currentRuleItem"
        :title="currentRuleItem ? currentRuleItem.name: ''"
        :visible.sync="ruleDialogVisible">
      <el-radio-group v-model="form.detectionType" v-if="currentRuleItem.id=='rt_1'">
        <el-radio label="rt_1_1_1">{{$t('datacenter.dataQuality.monitoringTask.testNum')}}</el-radio>
        <el-radio label="rt_1_1_2_1">{{$t('datacenter.dataQuality.monitoringTask.tooBig')}}</el-radio>
        <el-radio label="rt_1_1_2_2">{{$t('datacenter.dataQuality.monitoringTask.tooSmall')}}</el-radio>
      </el-radio-group>
      <el-radio-group v-model="form.detectionType" v-if="currentRuleItem.id=='rt_2'">
        <el-radio label="rt_3_1">{{$t('datacenter.dataQuality.monitoringTask.b1')}}</el-radio>
        <el-radio label="rt_3_1_1">{{$t('datacenter.dataQuality.monitoringTask.b2')}}</el-radio>
        <el-radio label="rt_3_1_2">{{$t('datacenter.dataQuality.monitoringTask.b3')}}</el-radio>
      </el-radio-group>
      <el-radio-group v-model="form.detectionType" v-if="currentRuleItem.id=='rt_3'">
        <el-radio label="rt_2_1_1">{{$t('datacenter.dataQuality.monitoringTask.c1')}}</el-radio>
        <el-radio label="rt_2_1_2">{{$t('datacenter.dataQuality.monitoringTask.c2')}}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ruleDialogVisible = false">{{$t('cancelText')}}</el-button>
        <el-button type="primary" @click="ruleDialogConfirm">{{$t('submitText')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :title="$t('datacenter.dataQuality.monitoringTask.addTestParams')"
        :visible.sync="setRulesDialogVisible">
      <el-form ref="setRulesForm" :model="setRulesForm" label-width="120px">
        <el-form-item :label="$t('datacenter.dataQuality.monitoringTask.judgmentType')">
            <el-select v-model="setRulesForm.type" :placeholder="$t('cip.cmn.rule.selectWarning')+$t('datacenter.dataQuality.monitoringTask.judgmentType')" @change="changeRulesType">
              <el-option v-for="(item,index) in testDataType" :key="index" :label="item.name" :value="item.type"></el-option>
           </el-select>
        </el-form-item>
        <div v-if="setRulesForm.type == 1">
          <el-form-item :label="$t('datacenter.dataQuality.monitoringTask.dataComparison')">
            <el-input clearable v-model="setRulesForm.jt_1_1" :disabled="setRulesForm.jt_1_2 ? true : false"></el-input>
          </el-form-item>
          <el-form-item :label="$t('datacenter.dataQuality.monitoringTask.outlierJudgment')">
            <el-input clearable v-model="setRulesForm.jt_1_2" :disabled="setRulesForm.jt_1_1 ? true : false"></el-input>
          </el-form-item>
        </div>
        <div v-if="setRulesForm.type == 2">
          <el-form-item :label="$t('datacenter.dataQuality.monitoringTask.intervalRangeValue')">
            <el-input clearable v-model="setRulesForm.jt_2_1"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRulesClose" size="mini">{{$t('cancelText')}}</el-button>
        <el-button type="primary" @click="setRulesConfirm" size="mini" style="margin-left:10px;">{{$t('submitText')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :title="$t('datacenter.dataQuality.monitoringTask.selectDataObj')"
        width="80%"
        :visible.sync="dataObjDialogVisible"
        @close="dataObjDialogClose">
      <el-row :gutter="10">
        <el-col span="6">
          <avue-tree
          :data="treeDic"
          :option="{
            props:{ label: 'name',value: 'id'},
            addBtn:false,
            menu:false
          }"
          @node-click="treeNodeClick">
        </avue-tree>
        </el-col>
        <el-col span="18">
          <el-table border ref="multipleTable" :data="dataObjTableData" tooltip-effect="dark" style="width: 100%"
          highlight-current-row>
          <el-table-column :label="$t('datacenter.dataQuality.monitoringTask.select')" align="center" width="65">
            <template slot-scope="scope">
              <el-radio :label="scope.$index" v-model="radio"
                        @change.native="selectDataObj(scope.row)">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" prop="code" label="编码" width="120">
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="label" label="标识" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" header-align="center" prop="sourceType" label="类型" show-overflow-tooltip>
          </el-table-column>
          <el-table-column header-align="center" align="center" prop="remark" label="备注" show-overflow-tooltip>
          </el-table-column>
        </el-table>
        <div style="text-align:right;margin-top:10px;">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
            :current-page="objPage.currentPage" :page-sizes="[1, 10, 20, 50,]" :page-size="objPage.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="objPage.total">
          </el-pagination>
        </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import cron from "@/views/components/cron";

import { getTreeList,save,getDetail} from "@/api/dataCenter/programManage";
import {ObjectList} from "@/api/dataAcquisition/index";
export default {
  data(){
    return {
      id:this.$route.query.id,
      form:{
        runType:1
      },
      cron:'',
      cronDialogVisible:false,
      active:0,
      setRulesForm:{},
      setRulesDialogVisible:false,
      treeDic:[],
      currentRuleItem:null,
      ruleDialogVisible:false,
      deterTableData:[],
      currentDaterItem: null,
      currentDaterIndex:null,
      ruleTemData:[
        {
          name:this.$t('datacenter.dataQuality.monitoringTask.accuracyVerif'),
          id:'rt_1'
        },
        {
          name:this.$t('datacenter.dataQuality.monitoringTask.consistencyVerif'),
          id:'rt_2'
        },
        {
          name:this.$t('datacenter.dataQuality.monitoringTask.timelinessVerif'),
          id:'rt_3'
        }
      ],
      testDataType:[
        {
          name:this.$t('datacenter.dataQuality.monitoringTask.numericalJudgment'),
          type:1
        },
        {
          name:this.$t('datacenter.dataQuality.monitoringTask.intervalJudgment'),
          type:2
        }
      ],
      dataObjDialogVisible:false,
      dataObjTableData:[],
      objTypeId:null,
      objPage: {
        pageSizes: [10, 30, 50, 100, 200],
        pageSize: 10,
        currentPage:1,
        total: 0
      },
      currentTemplateIndex:null,
      templateSelection: [],
      radio: '',
      dataObjList:[]
    }
  },
  components: { cron },
  methods: {
    //点击规则模块
    clickRuleItem(item) {
      this.currentRuleItem = item;
      this.ruleDialogVisible = true;
    },
    //规则模块确认
    ruleDialogConfirm() {
      this.ruleDialogVisible = false;
      this.form.ruleTemplate = this.currentRuleItem.id;
    },
    Changeradio(e){
      console.log(this.currentRuleItem,e.slice(0,4));

      this.form.ruleTemplate = e.slice(0,4)
    },
    //上一步
    prev() {
      this.active--;
      if(this.active < 0) {
        this.active = 0;
      }
    },
    //下一步
    next() {
      this.active++;
      if(this.active > 2) {
        this.active = 2;
      }
    },
    //添加判定方法
    addDater() {
      this.deterTableData.push({
        methodName:'',
        warningLevel:'',
        judgmentType:'',
        judmentArguments:''
      })
    },
    //设置规则确认
    setRulesConfirm() {
      let keys = '';
      for(var i in this.setRulesForm) {
        if(i !=="type" && this.setRulesForm[i] && this.setRulesForm[i]!==""){
          keys = i;
        }
      }
      this.currentDaterItem.judgmentType = keys;
      this.currentDaterItem.judmentArguments = keys ? this.setRulesForm[keys] : '';
      if(keys){
        this.currentDaterItem.type=this.setRulesForm.type;
      }
      this.deterTableData[this.currentDaterIndex] = this.currentDaterItem;
      this.setRulesDialogVisible = false;
    },
    //设置规则弹窗关闭
    setRulesClose() {
      this.currentDaterItem = null;
      this.currentDaterIndex = null;
      this.setRulesDialogVisible = false;
      this.setRulesForm = {};
    },
    //点击设置规则
    setDater(row,index) {
      this.currentDaterItem = row;
      this.currentDaterIndex = index;
      if(row.judgmentType=="jt_1_1" || row.judgmentType=="jt_1_2" ){
        this.setRulesForm.type = 1;
      }else if(row.judgmentType=="jt_2_1"){
        this.setRulesForm.type = 2;
      }
      // if(this.currentDaterItem.type) {
      //   this.setRulesForm.type = this.currentDaterItem.type;
      // }
      if(this.currentDaterItem.judgmentType && this.currentDaterItem.judmentArguments){
        this.$set(this.setRulesForm,this.currentDaterItem.judgmentType,this.currentDaterItem.judmentArguments);
      }
      this.setRulesDialogVisible = true;
    },
    changeRulesType(val) {
      this.setRulesForm = {
        type:val
      };
    },
    cronDialogConfirm(){
      this.form.corn=this.cron;
      this.cronDialogVisible = false;
    },
    addTemplate(){
      this.templateSelection.push({
        id:'',
        code:''
      })
    },
    delTemplate(index){
      console.log(index)
      this.templateSelection.splice(index,1)
    },
    clickSelectDataObj(index) {
      this.currentTemplateIndex = index;
      this.dataObjDialogVisible = true;
    },
    dataObjDialogClose() {
      this.radio = '';
      this.currentTemplateIndex = null;

    },
    treeNodeClick(row) {
      this.radio = '';
      this.objPage.currentPage = 1;
      this.objTypeId = row.id;
      this.getObjectListFn();
    },
    //获取数据对象列表
    getObjectListFn() {
      ObjectList({
        query:{
          current:this.objPage.currentPage,
          size:this.objPage.pageSize,
        },
        // dataObjectTypeId:this.objTypeId
        modelId:this.objTypeId
      }).then(res => {
        console.log(res)
        let {total,records = []} = res.data.data;
        this.dataObjTableData = records;
        this.objPage.total = total;
      })
    },
    handleSizeChange(val) {
      this.objPage.pageSize = val;
      this.getObjectListFn();
    },
    handleCurrentChange(val){
      this.radio = '';
      this.objPage.currentPage = val;
      this.getObjectListFn();
    },
    selectDataObj(row){

      // if(this.currentTemplateIndex){
        this.templateSelection[this.currentTemplateIndex] = row;
        console.log(row);
      // }
      this.dataObjDialogVisible = false;
    },
    //保存
    onSubmit() {
      this.form.judgmentMethodDTOList = this.deterTableData;
      let arr = [];
      this.templateSelection.forEach(item=>{
        if(item.id){
          arr.push(item.id);
        }
      });
      this.form.dataObject = [...new Set(arr)].join(',');
      if(this.id){
        this.form.id = this.id;
      }
      save(this.form).then(res => {
        this.$router.push({path:'/programManage/index'})
      })
    }
  },
  created() {
    getTreeList().then(res => {
      let data = res.data.data;
      this.treeDic= data;
    })
    //查找详情
    if(this.id) {
      getDetail(this.id).then(res => {
        let data = res.data.data;
        this.form = data;
        this.cron = data.corn;
        this.deterTableData = data.judgmentMethodDTOList;
        this.templateSelection = data.dataObjectMaps && data.dataObjectMaps.length>0 ? data.dataObjectMaps : [];
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .cron::v-deep{
    .el-tabs--border-card>.el-tabs__content{
      padding:15px !important;
      background:#ffffff;
    }
  }
  .el-radio-group {
    line-height: 20px !important;
}
  .rule-tem-list{
    display: flex;
    list-style: none;
    justify-content: center;
    li {
      list-style: none;
      width: 100px;
      height: 100px;
      border:1px solid #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
      &.active{
        border-color: #409EFF;
        color: #409EFF;
      }
    }
  }
</style>
